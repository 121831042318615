@import url(https://fonts.cdnfonts.com/css/poppins);
body {
    margin: 0;
    padding: 0;
    background: #fff;
    font-family: 'Poppins', sans-serif;
}

.form-group {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  padding: 0;
  border: none;
}

.form-control {
  margin-bottom: 10px;
}

.form-label {
  font-family: 'SF UI Display ', sans-serif; /* Apply SF UI Display font */
}

.answerCard {
  -webkit-appearance: none;
  display: flex;
  padding: 20px;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  width: 90%;
}

.answerSelectCard {
  -webkit-appearance: none;
  font-size: 1rem;
  width: 90%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: none;
  border-radius: 10px;

}


@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .avatar {
    animation: rotate 15s linear infinite; 
    transform-origin: center;
  }
